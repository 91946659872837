<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <b-table
      :items="items"
      :fields="fields"
      primary-key="id"
      show-empty
      empty-text="Brak danych serwisantów"
      class="position-relative"
    >

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
            <b-button variant="primary" class="mr-1" :to="{ name: 'apps-servicer', params: { id: data.item.id } }" v-b-tooltip.hover title="Edytuj serwisanta">
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button v-if="role_id == 1" variant="primary" class="mr-1" @click="deleteServicer(data.item.id)" v-b-tooltip.hover title="Usuń serwisanta">
              <feather-icon icon="TrashIcon" />
            </b-button>
        </div>
      </template>

    </b-table>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BBadge,
  BFormCheckbox, BDropdown, BDropdownItem, BFormSelect,
} from 'bootstrap-vue'
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  components: {
    BCard,
    BBadge,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BFormSelect,
  },
  data() {
    return {
      role_id: localStorage.getItem('id'),
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'firstname',
          label: 'Imię',
          sortable: true,
        },
        {
          key: 'lastname',
          label: 'Nazwisko',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'akcje',
        },
      ],
      items: [],
    }
  },
  mounted() {
    this.fetchServicers()
  },
  methods: {
    fetchServicers() {
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      axios.get(`${process.env.VUE_APP_API_URL}Servicer`, config).then(response => {
        this.items = response.data
      }).catch(error => {
        console.log(error)
      })
    },
    deleteServicer(id) {
      axios.delete(`${process.env.VUE_APP_API_URL}Servicer/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }).then(() => {
        Swal.fire(
          'Pomyślnie usunięto serwisanta',
          '',
          'success',
        )
        this.fetchServicers()
      }).catch(() => {
        Swal.fire(
          'Coś poszło nie tak!',
          '',
          'error',
        )
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
